import React from "react";
import { graphql } from "gatsby";

import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "@components/Layout";
import Seo from "@components/Seo";

import * as styles from "@styles/pages/other.module.scss";

type Props = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        desc: string;
      };
      body: any;
      slug: string;
    };
  };
};

const TermsOfServicePage: React.FC<Props> = ({
  data: {
    mdx: {
      body,
      slug,
      frontmatter: { title, desc },
    },
  },
}) => {
  return (
    <Layout>
      <Seo title={title} desc={desc} url={slug} />

      <article className={styles.page}>
        <section className={styles.page__details}>
          <h1 className={styles.page__title}>{title}</h1>
          <p className={styles.page__desc}>{desc}</p>
        </section>

        <section className={styles.page__content}>
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    mdx(frontmatter: { component_id: { eq: "page_terms" } }) {
      frontmatter {
        title
        desc
      }
      body
      slug
    }
  }
`;

export default TermsOfServicePage;
